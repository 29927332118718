.formSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-61xl);
  text-align: left;
  font-size: var(--font-size-51xl);
  font-family: var(--subhead-2);
  /* overflow: hidden; */
}

.mapImgMobile {
  display: none;
}

/* ------------------------------------------------ */
.formSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-61xl);
}

.headingContainer {
  position: relative;
  width: 57.7rem;
}
.orangeBox1 {
  background-color: var(--color-tomato-100);
  position: absolute;
  top: 6rem;
  left: 12rem;
  width: 42.5rem;
  height: 4.1rem;
}

.heading {
  position: relative;
  letter-spacing: 0.04em;
}

/* --------------------------------------------------- */
.inputText{
  font-weight: 400;
  font-family: var(--font-open-sans);
  background: var(--color-white);
  /* -webkit-background-clip: text; */
  color: black;
}

input:focus {
  outline:none;
}

.formRow{
  display: flex;
  margin: 0 0 5.2rem 0;
  gap: 3rem;
}

.inputData {
  width: 100%;
  height: 4rem;
  position: relative;
}

.inputText, .textarea {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 1.4rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.45)
}

::placeholder {
  color: transparent;
}


.textarea {
  height: 7rem;
  margin-top: 0;
  /* -webkit-background-clip: text; */
  resize: none;
}

.textarea:focus {
  outline: none;
}

.nameParent {
  width: 100%;
  position: relative;
  height: 35.1rem;
  font-size: var(--font-size-xs);
  color: #817e7e;
  font-family: var(--font-open-sans);
}
/* --------------------------------------------------- */

.button {
  overflow: hidden;
  flex-direction: row;
  background-color: var(--color-tomato-100);
  border: 1px solid var(--color-tomato-100);
  padding: var(--padding-xs) var(--padding-xl);
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-5xs);
  color: var(--color-white);
  margin-top: 5rem;
  font-size: 1.6rem;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow:inset  1px 2px 2px rgba(16, 24, 40, 0.1)
}

.button:hover {
  background: transparent;
  box-shadow: none;
  
  color: #f75c14;
 
}

.nameParent {
  height: auto;
}

/* ---------------------------------------------------- */
.orangeBox {
  background-color: var(--color-tomato-100);
  position: absolute;
  width: 32.5rem;
  height: 33.1rem;
  top: 21rem;
  left: 27.5rem;
}
.mapImg {
  position: relative;
  width: 59.2rem;
  height: 52.78rem;
  object-fit: cover;
  border: none;
}
.mapContainer {
  position: relative;
}

/* ------------------------------------------------------ */


@media only screen and (max-width: 1280px) {
  .formSection {
    gap: 5rem;
  }

  .mapImg {
    width: 50rem;
    height: 50rem;
  }

  .orangeBox {
    left: 20.5rem;
    top: 17.5rem;
  }

  .formSection {
    font-size: 6rem;
  }

  .orangeBox1 {
    top: 5rem;
    left: 5rem;
  }

  .headingContainer {
    width: 49.7rem;
  }

  
}

@media only screen and (max-width: 1140px) {
  .headingContainer {
    width: 33.7rem;
    line-height: 120%;
  }

  .formSection {
    font-size: 8rem;
    gap: 6rem;
  }

  .orangeBox1 {
    top: 14rem;
    left: 8rem;
    width: 26.5rem;
    height: 8.1rem;
  }

  .mapImg {
    display: none;
  }

  .mapImgMobile {
    position: relative;
    display: block;
    width: 40rem;
    height: 45rem;
  }

  .orangeBox {
    width: 26.5rem;
    left: 12.5rem;
    top: 14.5rem;
    height: 29.1rem;
  }

  .inputText {
    font-size: 1.2rem;
    padding-left: 1rem;
  }

  .textarea {
    height: 12rem;
    width: 40rem;
    padding-left: 1rem;
  }

  .button {
    margin-top: 10rem;
  }

  .formRow {
    width: 40rem;
  }

}


@media only screen and (max-width: 900px) {
  .formSection {
    font-size: 5.6rem;
    align-items: flex-end;
  }

  .label {
    display: none;
  }

  .inputText::placeholder, .textarea::placeholder {
    color: lightgray;
    font-size: 1.2rem;
  }

  .headingContainer {
    width: 25rem;
  }

  .orangeBox1 {
    top: 10rem;
    left: 5rem;
    width: 20.5rem;
    height: 6.1rem;
  }

  .formRow {
    width: 35rem;
  }

  .textarea {
    width: 35rem;
    padding: 1rem;
  }

  .mapImgMobile {
    position: relative;
    display: block;
    width: 30rem;
    height: 35rem;
  }

  .orangeBox {
    width: 19.5rem;
    left: 7rem;
    top: 11.5rem;
    height: 19.1rem;
  }

  .mapContainer {
    margin-bottom: 12rem;
  }
}


@media only screen and (max-width: 750px) {
  .inputText, .textarea {
    border: 1px solid #817e7e;
  }

  .inputData {
    height: 3rem;
  }

  .formSection {
    flex-direction: column;
    font-size: 4.4rem;
    align-items: flex-start;
    gap: 9rem;
  }

  .inputText::placeholder, .textarea::placeholder {
    font-size: 1rem;
    color: rgb(217, 215, 215);
  }

  .orangeBox1 {
    top: 8rem;
    left: 4rem;
    width: 15.5rem;
    height: 5.1rem;
  }

  .mapImgMobile {
    width: 40rem;
    height: 40rem;
  }

  .orangeBox {
    width: 32.5rem;
    left: 7rem;
    top: 11.5rem;
    height: 32.1rem;
  }
}

@media only screen and (max-width: 540px) {
  .mapImgMobile {
    width: 30rem;
    height: 30rem;
  }

  .orangeBox {
    width: 19.5rem;
    left: 7rem;
    top: 11.5rem;
    height: 19.1rem;
  }

  .formSection {
    font-size: 3.2rem;
  }

  .orangeBox1 {
    top: 5.5rem;
    left: 3rem;
    width: 11.5rem;
    height: 3.1rem;
  }

  .formSectionContainer {
    gap: 4rem;
  }

  .nameParent {
    font-size: 1rem;
  }

  .inputData {
    height: 2.5rem;
  }

  .formRow, .textarea {
    width: 27.5rem;
  }
}





