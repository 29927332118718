.whiteLogo {
  position: relative;
  object-fit: contain;
  cursor: pointer;
  width: 13rem;
}
.home1 {
  margin: auto;
  width: max-content;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  transition: all 0.3s;
}

.home1:hover
{
  color: var(--color-tomato-100);
}

.component10,
.component6,
.component8,
.component9 {
  position: relative;
  font-weight: 700;
  width: 100%;
  height: 19px;
  cursor: pointer;
  text-align: center;
}

.vectorIcon {
  height: 65%;
  width: 100%;
  margin: auto;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  text-align: center;
  padding-top: 5px;
}
.icroundSearch {
  position: relative;
  background: radial-gradient(
    50% 50%at 50% 50%,
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0)
  );
  width: 100%;
  height: 32px;
  overflow: hidden;
}
.header, .componentParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.componentParent {
  width: 60%;
  padding: var(--padding-base);
  box-sizing: border-box;
  gap: 1.2rem;
  
}
.header {
  position: absolute;
  padding: 20px 5%;
  width: 90%;
  text-align: left;
  font-size: var(--text-md-medium-size);
  color: var(--color-gray-400);
  font-family: var(--font-open-sans);
  overflow: hidden;
  z-index: 10000;
  color: white;
}


.mobile{
  background: transparent;
  border: none;
  padding: 5px;
  cursor: pointer;
  outline: none;
  display: none;
  font-size: 3.2rem;

}

.mobile i {
  color: var(--color-tomato-300);
  align-items: center;
}

@media only screen and (max-width: 1180px) {
.component6Parent {
  width: 60%;
  gap: .64rem;
}

.header {
  left: 0;
  right: 0;
  width: auto;
  padding: 1.5rem 2%;
  font-size: var(--font-size-smi);
}
}

@media only screen and (max-width: 840px) {

.responsiveNav {
		transform: none;
}
.mobile{
  display: block;
  z-index: 1000;
}
.componentParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    right: -300px;
    width: 275px;
    height: 90vh;
    background: #E1E1E1;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 16rem 0 0 10px;
    transition: 0.3s ease-in-out;
    color: black;
}

.active {
  right: 0;
}

.component10,
.component6,
.component8,
.component9 {
  margin: 0 0 1.6rem 2.88rem;
  text-align: left;
  width: auto;
  font-weight: 600;
}

.icroundSearch {
  display: none;
}

.header {
  width: auto;
  padding: 1rem 2%;
  padding-right: 1.6rem;
}


}

@media only screen and (max-width: 680px) {
  

  .whiteLogo {
    width: 13rem;
  }

  .mobile {
    font-size: 2.4rem;
    padding: 0;
  }

}

@media only screen and (max-width: 560px) {
  

  .whiteLogo {
    width: 11rem;
  }

  .mobile {
    font-size: 2rem;
    padding: 0;
  }

  .header {
    padding: .5rem 1.5rem;
  }

}