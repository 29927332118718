.blogDescription {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
  text-align: left;
  font-size: 6.4rem;
  color: var(--main);
  font-family: var(--subhead-2);
}

/* ------------------------------------------------------------- */

.Img1,
.Img2 {
  position: absolute;
  top: 137.3rem;
  left: 3.9rem;
  width: 51.8rem;
  height: 52.1rem;
  object-fit: cover;
}
.Img2 {
  top: 244.8rem;
  left: 91.7rem;
  width: 47.6rem;
  height: 45.4rem;
}

/* --------------------------------------------------------- */

.pageHeading {
  letter-spacing: 0.1em;
  display: inline-block;
  font-weight: 400;
  margin: 7rem;
  font-size: 10rem;
}
.heroSection {
  width: 100%;
  height: 60rem;
}

.bgImg {
  background-image: url("/public/hero-section@3x.jpg");
  position: relative;
  width: inherit;
  height: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: flex-end;
}

/* -------------------------------------------------- */

.postParent {
  display: flex;
  flex-direction: column;
  margin-top: 23rem;
  align-items: center;
  gap: var(--gap-141xl);
  font-size: var(--font-size-sm);
  color: var(--color-black);
  font-family: var(--font-open-sans);
}

.post1 {
  position: relative;
  width: auto;
}

.postHeadingContainer {
  position: relative;
  font-size: var(--subhead-2-size);
  letter-spacing: 0.06em;
  line-height: 135.54%;
  font-family: var(--subhead-2);
  color: var(--color-gray-300);
  display: inline-block;
  width: 76.2rem;
}

.postHeading {
  margin: 0;
}

.post1Wrapper, .post2Wrapper {
  background-color: var(--main);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  padding: var(--padding-21xl) var(--padding-45xl);
  justify-content: center;
  margin-top: 8rem;
  text-align: justify;
}

.post1Child {
  position: absolute;
  top: 10.3rem;
  left: 23.2rem;
  background-color: var(--color-tomato-100);
  width: 33.9rem;
  height: 5.7rem;
}

.postContent {
  letter-spacing: 0.06em;
  line-height: 163.54%;
  width: 68rem;
  flex-shrink: 0;
  font-weight: 600;
}

/* ---------------------------------------------------- */

.post2 {
  position: relative;
  width: auto;
}

.post2Child {
  position: absolute;
  top: 3rem;
  left: 13.5rem;
  background-color: var(--color-tomato-100);
  width: 20.8rem;
  height: 5.5rem;
}

.post2Subhead {
  line-height: 128.54%;
  margin-top: 6.4rem;
  font-size: var(--font-size-5xl);
  letter-spacing: 0.04em;
  font-weight: 600;
  font-family: var(--font-playfair-display);
  color: var(--color-black);
  width: 80.8rem;
}

.post2Wrapper {
  margin-top: 5rem;
  font-size: var(--font-size-sm);
}



@media only screen and (max-width: 1180px) {
  .Img1 {
    left: -8rem;
    width: 41.8rem;
    height: 42.1rem;
    top: 143rem;
  }

  .Img2 {
    left: 69.7rem;
    width: 37rem;
    height: 35rem;
    top: 257rem;
  }

  .pageHeading {
    font-size: 7rem;
    margin: 10rem;
  }

}

@media only screen and (max-width: 920px) {
  .post2 {
    display: none;
  }

  .pageHeading {
    font-size: 6rem;
  }

  .bgImg {
    background-position:left;
  }

  .postParent {
    margin-top: 15rem;
  }

  .postHeadingContainer {
    font-size: 3.6rem;
    width: 45rem;
    letter-spacing: 0.04em;
  }

  .post1Child {
    left: 11.6rem;
    top: 7.2rem;
  }

  .postContent {
    width: 60rem;
  }

  .Img1 {
    left: -8rem;
    width: 41.8rem;
    height: 42.1rem;
    top: 140rem;
  }

}

@media only screen and (max-width: 800px) {
  .postContent {
    width: 45rem;
  }

  .postHeadingContainer {
    font-size: 2.8rem;
    width: 35rem;
    letter-spacing: 0.04em;
  }

  .post1Child {
    left: 7.6rem;
    top: 5.7rem;
    width: 28rem;
    height: 5.2rem;
  }

  .postParent {
    font-size: var(--font-size-xs);
  }

}

@media only screen and (max-width: 640px) {
  .pageHeading {
    font-size: 4rem;
    margin: 4rem;
  }

  .heroSection {
    height: 30rem;
  }

  .postParent {
    font-size: var(--font-size-4xs);
    margin-top: 10rem;
  }

  .postContent {
    width: 25rem;
  }

  .post1Wrapper, .post2Wrapper {
    padding: var(--padding-11xl) 3rem;
    margin-top: 6rem;
  }

  .postHeadingContainer {
    font-size: 2rem;
    width: 30rem;
    letter-spacing: 0.04em;
  }

  .post1Child {
    left: 5.8rem;
    top: 4.2rem;
    width: 18rem;
    height: 3.2rem;
  }

  .Img1 {
    left: -6rem;
    width: 29rem;
    height: 29.3rem;
    top: 97rem;
  }

}

@media only screen and (max-width: 320px) {
  .postHeadingContainer {
    font-size: 1.5rem;
    width: 20rem;
    letter-spacing: 0.02em;
  }

  .post1Child {
    left: 3.8rem;
    top: 3rem;
    width: 15rem;
    height: 2.8rem;
  }

  .post1Wrapper, .post2Wrapper {
    padding: var(--padding-11xl) 2rem;
    margin-top: 5rem;
  }

  .postContent {
    width: 18rem;
  }

  .postParent {
    font-size: 7px;
    margin-top: 8rem;
  }

  .Img1 {
    left: -5rem;
    width: 21rem;
    height: 21.3rem;
    top: 88rem;
  }

  .pageHeading {
    font-size: 3rem;
    margin: 3rem;
  }
}



