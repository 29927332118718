.icon {
  width: 226px;
  height: 213px;
  object-fit: cover;
}
.kajCastIron {
  letter-spacing: 0.04em;
}
.parent {
  position: relative;
  background-color: var(--color-gainsboro-200);
  width: 320px;
  height: 320px;
  text-align: left;
  font-size: var(--text-md-medium-size);
  color: var(--color-black);
  font-family: var(--font-open-sans);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

@media only screen and (max-width: 1180px) {
  .parent {
    width: 260px !important;
    height: 320px !important;
  }

  .kajCastIron {
    width: max-content;
    font-size: var(--font-size-xs);
  }

  /* .icon {
    width: 162px !important;
    height: 160px !important;
  } */

}

@media only screen and (max-width: 840px) {
  .parent {
    width: 200px !important;
    height: 230px !important;
  }

  .kajCastIron {
    width: max-content;
    font-size: var(--font-size-4xs);
  }

  /* .icon {
    width: 132px !important;
    height: 130px !important;
  } */

}

@media only screen and (max-width: 680px) {

  .parent {
    width: 160px !important;
    height: 200px !important;
  }
  .kajCastIron {
    width: 90%;
    font-size: var(--font-size-sm);
    text-align: center;
  }

  .icon {
    width: 125px;
    height: 93px;
  }

}
