.heroSection {
  width: 100%;
  height: 69rem;
  overflow: hidden;
  font-family: var(--subhead-2);
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.backgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: inherit;
  object-fit: cover;
}

.pageHeading {
  position: relative;
  font-family: var(--subhead-2);
  letter-spacing: 0.06em;
  width: 96rem;
}

.pencilsketchadjusted25321021Icon,
.pencilsketchadjusted50372865Icon {
  /* position: absolute;
  top: 69rem;
  left: 15.6rem; */
  width: 47.6rem;
  height: 45.4rem;
  object-fit: cover;
}
.pencilsketchadjusted25321021Icon {
  width: 48.1rem;
  height: 46.4rem;
}
/* --------------------------------------------------- */
.envelopeContainer {
  position: absolute;
  top: 60rem;
  right: 3rem;
  background-color: var(--color-white);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl) 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xl);
  font-size: 2.1rem;
  color: var(--color-black);
}

.envelopeIcon {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.envelopeText {
  position: relative;
  letter-spacing: 0.06em;
  line-height: 131.54%;
  display: inline-block;
  width: 15rem;
}

/* ----------------------------------------------------- */

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 18rem;
  gap: var(--gap-141xl);
  text-align: left;
  font-size: var(--font-size-44xl);
  color: var(--color-darkslategray-100);
}
.orangeBox {
  position: absolute;
  top: 11.3rem;
  left: -1rem;
  background-color: var(--color-tomato-100);
  width: 41.7rem;
  height: 6.8rem;
}
.our {
  margin: 0;
}
.heading {
  position: relative;
  letter-spacing: 0.06em;
  line-height: 127.54%;
}

.section1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 11.6rem;
  text-align: center;
  font-family: var(--subhead-2);
}

.section1HeadingContainer {
  position: relative;
}

.section1Group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
}

.section1Content {
  position: relative;
  text-align: justify;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-open-sans);
  display: flex;
  flex-direction: row;
  gap: 5rem;
}
.Img1,
.Img2 {
  height: 37.52%;
  width: 47%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.Img1 {
  height: 34.83%;
  width: 35%;
}
.line {
  border-right: 3px solid var(--color-tomato-100);
  box-sizing: border-box;
  width: 0.3rem;
  height: 27.8rem;
}
.costEffectiveAndSuperior {
  color: var(--color-tomato-100);
}
.group1Text,
.group2Text {
  letter-spacing: 0.06em;
  line-height: 157.54%;
  display: inline-block;
}
.group1Text {
  width: 28.8rem;
  margin-top: 1.9rem;
}
.group2Text {
  width: 29.6rem;
}
/* ------------------------------------------------------- */

.frameParent2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-24xl);
  color: var(--color-black);
}
.section2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  color: var(--color-black);
  gap: var(--gap-25xl);
  font-size: var(--font-size-sm);
  color: var(--color-white);
}

/* ------------------------------- */
.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  justify-content: flex-end;
  height: auto;
  padding: 0 0 9rem 1rem;
}
.gridItem1 {
  position: relative;
  background-color: var(--color-tomato-100);
  height: 100%;
  width: 100%;
  display: flex;
}

.item1Text {
  letter-spacing: 0.06em;
  line-height: 159.54%;
  display: inline-block;
  width: 35.3rem;
  margin-left: 4rem;
}

/* ----------------------------- */


.item4Text {
  color: #939393;
}
.item4Container {
  letter-spacing: 0.06em;
  line-height: 137.54%;
  display: inline-block;
  width: 37.7rem;
  margin-left: 2rem;
}
.kesarIronLogo {
  width: 13.4rem;
  height: 13.7rem;
  overflow: hidden;
}
.gridItem4 {
  position: relative;
  background-color: #d2d2d2;
  height: 100%;
  font-size: var(--font-size-13xl);
  color: var(--color-white);
  font-family: var(--subhead-2);
  display: flex;
  padding-left: 1rem;
}

/* ---------------------------------- */

.gridItem2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5rem;
  position: relative;
  color: var(--color-black);
}

.group3 {
  background-color: var(--color-white);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  padding: 9.1rem 7.6rem;
  align-items: flex-start;
  justify-content: flex-start;
}

.youtubeLogo {
  position: relative;
  width: 10.5rem;
  overflow: hidden;
  flex-shrink: 0;
}

.group4 {
  background-color: var(--color-gainsboro-200);
  display: flex;
  flex-direction: column;
  padding: 0 2.5rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-around;
}
.icon3 {
  position: relative;
  width: 20.7rem;
  object-fit: cover;
}

.roundIronGrill {
  position: relative;
  letter-spacing: 0.04em;
}
.group1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1rem;
  background-color: var(--color-gainsboro-200);
  gap: 3rem;
}

.icon1 {
  position: relative;
  width: 19.7rem;
  height: 13.2rem;
  object-fit: cover;
}

.icon2 {
  width: 16.6rem;
  height: 18.6rem;
  object-fit: cover;
}

.group2 {
  background-color: var(--color-gainsboro-200);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 2rem 0;
  gap: 3rem;
}

/* ---------------------------------- */
.handImg {
  width: 17.7rem;
  height: 17.7rem;
  overflow: hidden;
}

/* ----------------------------------- */


.group7 {
  background-color: var(--color-gainsboro-200);
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs) var(--padding-24xl) 1.8rem;
  align-items: center;
  justify-content: flex-start;
}

.icon7 {
  position: relative;
  width: 17.1rem;
  height: 20.9rem;
  object-fit: cover;
}

.group8 {
  background-color: var(--color-gainsboro-200);
  display: flex;
  flex-direction: column;
  padding: 3.8rem 5.4rem var(--padding-base);
  align-items: center;
  justify-content: center;
  gap: var(--gap-13xl);
  text-align: center;
}

.icon8 {
  position: relative;
  width: 14.9rem;
  height: 13.2rem;
  object-fit: cover;
}

.group5 {
  background-color: var(--color-gainsboro-200);
  display: flex;
  flex-direction: column;
  padding: 3.5rem 2.2rem;
  align-items: center;
  justify-content: center;
  gap: 2.6rem;
}

.icon5 {
  position: relative;
  width: 15.7rem;
  height: 14.2rem;
  object-fit: cover;
}

.group6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-gainsboro-200);
}

.button {
  overflow: hidden;
  flex-direction: row;
  background-color: var(--color-tomato-100);
  border: 1px solid var(--color-tomato-100);
  padding: var(--padding-xs) var(--padding-xl);
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-5xs);
  color: var(--color-white);
  font-size: 1.6rem;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow:inset  1px 2px 2px rgba(16, 24, 40, 0.1)
}

.button:hover {
  background: transparent;
  box-shadow: none;
  
  color: #f75c14;
 
}

/* ----------------------------------- */

/* ---------------------------------------- */
.para1,
.para2 {
  position: relative;
  letter-spacing: 0.06em;
  line-height: 157.04%;
  display: inline-block;
  width: 42.4rem;
  flex-shrink: 0;
}
.para2 {
  font-size: var(--font-size-sm);
  line-height: 159.54%;
  width: 45.8rem;
  height: 12.6rem;
}

.section3 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 25.6rem;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
}

/* ------------------------------------------- */

.homepage {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-45xl);
  color: var(--color-white);
  font-family: var(--font-open-sans);
}

/* -------------------------------------------- */

@media only screen and (max-width: 1300px) {
  .heroSection {
    align-items: center;
  }
}

@media only screen and (max-width: 1150px) {
  .pageHeading {
    font-size: 4.8rem;
    width: 80rem;
  }

  .envelopeContainer {
    gap: 1.8rem;
  }

  .section3 {
    gap: 13.6rem;
    font-size: var(--font-size-xl);
    color: var(--color-black);
  }

  .para1 {
    width: 35.5rem;
  }

  .para2 {
    height: 10.2rem;
    width: 43.8rem;
    width: 34.8rem;
    font-size: 1.2rem;
  }

  .gridItem1 {
    height: 37.1rem;
    width: 37.1rem;
  }

  .textContainer {
    padding: 0 0 4rem 0.5rem ;
  }

  .handImg {
    width: 12.2rem;
    height: 12.3rem;
  }

  .item1Text {
    margin-left: 2.5rem;
    width: 24.3rem;
    font-size: 1.2rem;
  }

  .gridItem4 {
    width: 37.1rem;
    height: 37.1rem;
    padding: 0;
  }

  .kesarIronLogo {
    width: 9.3rem;
    height: 9.3rem;
  }

  .item4Container {
    font-size: 2.4rem;
    width: 29.8rem;
  }

  .group1 {
    width: 17.7rem;
    height: 17.7rem;
    padding: 0;
    justify-content: space-evenly;
    gap: 3rem;
  }

  .icon1 {
    width: 12.9rem;
    height: 7.7rem;
  }

  .roundIronGrill {
    font-size: 1.2rem;
    width: 16.1rem;
    text-align: center;
  }

  .gridItem2 {
    height: 100%;
    gap: 1.5rem;
  }
  .group2 {
    padding: 0;
    gap: 0;
    width: 17.7rem;
    height: 17.7rem;
    justify-content: space-evenly;
  }

  .icon2 {
    height: 12.6rem;
    width: 12.6rem;
  }

  .group3 {
    width: 17.7rem;
    height: 17.7rem;
    padding: 0;
    justify-content: center;
    align-items: center;
  }

  .youtubeLogo {
    width: 5.5rem;
  }

  .group4 {
    width: 17.7rem;
    height: 17.7rem;
    padding: 0;
    gap: 0;
  }

  .icon3 {
    width: 14.4rem;
  }
  
  .group5 {
    width: 17.7rem;
    height: 17.7rem;
    padding: 0;
  }

  .icon5 {
    width: 10.9rem;
    height: 9.8rem;
  }

  .group7 {
    width: 17.7rem;
    height: 17.7rem;
    padding: 0;
  }

  .icon7 {
    width: 11.8rem;
    height: 14.4rem;
  }

  .group8 {
    padding: 0;
    gap: 0;
    justify-content: space-around;
  }

  .icon8 {
    width: 10.2rem;
    height: 9.1rem;
  }

  .group6 {
    background-color: transparent;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 900px) {
  .pageHeading {
    width: 70rem;
  }

  .section2 {
    gap: 2rem;
  }

  .section3 {
    gap: 7.1rem;
    font-size: 1.6rem;
  }

  .para1 {
    width: 28.5rem;
  }

  .para2 {
    font-size: 1rem;
    width: 28.7rem;
    height: 7.2rem;
  }


  .gridItem1 {
    height: 34rem;
    width: 34rem;
  }

  .textContainer {
    padding: 0 0 3rem 0rem ;
  }

  .handImg {
    width: 11.2rem;
    height: 11.3rem;
  }

  .item1Text {
    margin-left: 2.5rem;
    width: 22.3rem;
    font-size: 1rem;
  }

  .gridItem4 {
    width: 34rem;
    height: 34rem;
    padding: 0;
  }

  .kesarIronLogo {
    width: 8.3rem;
    height: 8.3rem;
    margin-left: 1rem;
  }

  .item4Container {
    font-size: 2rem;
    width: 24.8rem;
    margin-left: 2rem;
  }

  .group1 {
    width: 16.2rem;
    height: 16.2rem;
    padding: 0;
    justify-content: space-evenly;
    gap: 3rem;
  }

  .icon1 {
    width: 11.9rem;
    height: 6.7rem;
  }

  .roundIronGrill {
    font-size: 1rem;
    width: 10.5rem;
    text-align: center;
  }

  .gridItem2 {
    height: 100%;
    gap: 1.5rem;
  }
  .group2 {
    padding: 0;
    gap: 0;
    width: 16.2rem;
    height: 16.2rem;
    justify-content: space-evenly;
  }

  .icon2 {
    height: 10.6rem;
    width: 12.6rem;
  }

  .group3 {
    width: 16.2rem;
    height: 16.2rem;
    padding: 0;
    justify-content: center;
    align-items: center;
  }

  .youtubeLogo {
    width: 4.5rem;
  }

  .group4 {
    width: 16.2rem;
    height: 16.2rem;
    padding: 0;
    gap: 0;
  }

  .icon3 {
    width: 12.4rem;
  }
  
  .group5 {
    width: 16.2rem;
    height: 16.2rem;
    padding: 0;
  }

  .icon5 {
    width: 9.9rem;
    height: 8.8rem;
  }

  .group7 {
    width: 16.2rem;
    height: 16.2rem;
    padding: 0;
  }

  .icon7 {
    width: 9.8rem;
    height: 13.4rem;
  }

  .group8 {
    padding: 0;
    gap: 0;
    justify-content: space-around;
  }

  .icon8 {
    width: 9.2rem;
    height: 8.1rem;
  }

  .group6 {
    background-color: transparent;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 700px) {
  .pageHeading {
    width: 57rem;
    font-size: 3.6rem;
  }

  .envelopeContainer {
    font-size: 1.8rem;
    padding: 1.5rem 0;
  }

  .envelopeIcon {
    width: 4.6rem;
    height: 4.6rem;
  }
  .section2 {
    gap: 2rem;
  }

  .envelopeText {
    width: 12rem;
  }

  .section1HeadingContainer {
    font-size: 4.8rem;
    text-align: left;
  }

  .orangeBox {
    top: 9.3rem;
    left: 5rem;
    width: 33.7rem;
    height: 5.8rem;
  }

  .content {
    margin-top: 14rem;
  }

  .section1Content {
    gap: 3rem;
    font-size: 1.2rem;
  }

  .group1Text {
    margin-top: 0.9rem;
    width: 24.8rem;
  }

  .group2Text {
    width: 23.8rem;
  }
  .section3 {
    gap: 5.1rem;
    font-size: 1.6rem;
  }

  .para1 {
    width: 28.5rem;
  }

  .para2 {
    font-size: 1rem;
    width: 28.7rem;
    height: 7.2rem;
  }


  .gridItem1 {
    height: 30rem;
    width: 30rem;
  }

  .gridItem4 {
    width: 30rem;
    height: 30rem;
    padding: 0;
  }

  .group1 {
    width: 14.5rem;
    height: 14.5rem;
    gap: 3rem;
  }

  .icon1 {
    width: 11.9rem;
    height: 6.7rem;
  }

  .roundIronGrill {
    font-size: 1rem;
    width: 10.5rem;
    text-align: center;
  }

  .gridItem2 {
    height: 100%;
    gap: 1rem;
  }
  .group2 {
    padding: 0;
    gap: 0;
    width: 14.5rem;
    height: 14.5rem;
    justify-content: space-evenly;
  }

  .icon2 {
    height: 10.6rem;
    width: 12.6rem;
  }

  .group3 {
    width: 14.5rem;
    height: 14.5rem;
  }

  .youtubeLogo {
    width: 2.5rem;
  }

  .group4 {
    width: 14.5rem;
    height: 14.5rem;
  }

  .icon3 {
    width: 10.4rem;
  }
  
  .group5 {
    width: 14.5rem;
    height: 14.5rem;
  }

  .icon5 {
    height: 7.8rem;
  }

  .group7 {
    width: 14.5rem;
    height: 14.5rem;
  }

  .icon7 {
    width: 9.8rem;
    height: 11.4rem;
  }

  .group8 {
    padding: 0;
    gap: 0;
    justify-content: space-around;
  }

  .icon8 {
    width: 9.2rem;
    height: 8.1rem;
  }

  .group6 {
    background-color: transparent;
    justify-content: flex-end;
  }
}


@media only screen and (max-width: 630px) {

  .content {
    align-items: flex-start;
    margin-left: 5rem;
    gap: 10rem;
  }
  .homepage {
    background-color: var(--color-white);
  }

  .section1HeadingContainer {
    font-size: 3.4rem;
  }

  .orangeBox {
    top: 6.300000000000001rem;
    left: 10rem;
    width: 17.700000000000003rem;
    height: 3.8rem;
  }
  .section1Content {
    flex-direction: column;
    align-items: flex-start;
    gap: 7rem;
  }

  .section1 {
    align-items: flex-start;
    gap: 9rem;
  }

  .section2 {
    grid-template-columns: 1fr;
    gap: 4rem;
  }

  .group5, .group7, .group8, .group3 {
    display: none;
  }

  .gridItem2 {
    grid-template-columns: 1fr;
    gap: 4rem;
  }

  .line {
    border-right: none;
    border-top: 3px solid var(--color-tomato-100);
    height: 0.3rem;
    width: 24.5rem;
  }

  .section1Group {
    align-items: flex-start;
    gap: 2rem;
  }

  .group1Text {
    width: 20rem;
  }

  .Img1 {
    width: 50%;
  }

  .Img2 {
    width: 52%;
  }

  .group2Text {
    width: 19.8rem;
  }

  .group6 {
    flex-direction: row;
    justify-content: flex-start;
  }

  .gridItem1 {
    width: 24.8rem;
    height: 21.4rem;
  }

  .handImg {
    width: 7rem;
    height: 7.1rem;
  }

  .item1Text {
    margin-left: 1.5rem;
    width: 19.3rem;
  }

  .textContainer {
    padding-bottom: 1rem;
  }

  .group1, .group2, .group4 {
    width: 24.8rem;
    height: 24.8rem;
    justify-content: center;
  }

  .icon1 {
    width: 18.1rem;
    height: 10.8rem;
  }

  .roundIronGrill {
    font-size: 1.4rem;
    width: 25.5rem;
  }

  .icon2 {
    width: 20.6rem;
    height: 18.6rem;
  }

  .group2 {
    gap: 1rem;
  }

  .icon3 {
    width: 20rem;
  }

  .group1 {
    justify-content: space-around;
  }

  .gridItem4 {
    width: 24.8rem;
    height: 22.6rem;
  }

  .kesarIronLogo {
    width: 7.8rem;
    height: 7.6rem;
  }

  .item4Container {
    font-size: 1.4rem;
    width: 16.8rem;
  }

  .textContainer {
    gap: 2rem;
  }

  .section3 {
    flex-direction: column;
  }

  .para1 {
    font-size: 1.4rem;
    width: 24.5rem;
  }

  .para2 {
    font-size: 1rem;
    width: 25.4rem;
  }

  .pageHeading {
    font-size: 3rem;
    width: 34rem;
  }

  .heroSection {
    height: 45rem;
  }

  .envelopeContainer {
    top: 38rem;
  }

  
}
