.orangeBox {
  position: absolute;
  background-color: var(--color-tomato-100);
  width: 26.9rem;
  height: 6rem;
  top: 4rem;
  left: 13rem;
}
.quickEnquiry {
  position: relative;
  letter-spacing: 0.06em;
  line-height: 127.54%;
}
.headingParent {
  position: relative;
  width: 39.5rem;
  height: 10rem;
}


.frameParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-189xl);
  text-align: left;
  font-size: var(--font-size-44xl);
  color: var(--color-darkslategray-100);
  font-family: var(--subhead-2);
}

.inputText{
  font-weight: 400;
  font-family: var(--font-open-sans);
  background: var(--color-white);
  /* -webkit-background-clip: text; */
  color: black;
}

input:focus {
  outline:none;
}

.formRow{
  display: flex;
  margin: 0 0 5.2rem 0;
  gap: 3rem;
}

.inputData {
  width: 100%;
  height: 4rem;
  position: relative;
}

.inputText, .textarea {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 1.4rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.45)
}

.textarea {
  height: 7rem;
  /* -webkit-background-clip: text; */
  resize: none;
}

.textarea:focus {
  outline: none;
}

.nameParent {
  position: relative;
  height: 35.1rem;
  font-size: var(--font-size-xs);
  color: #817e7e;
  font-family: var(--font-open-sans);
  height: auto;
}
/* --------------------------------------------------- */

.button {
  overflow: hidden;
  position: relative;
  left: 44rem;
  background-color: var(--color-tomato-100);
  border: 1px solid var(--color-tomato-100);
  padding: var(--padding-xs) var(--padding-xl);
  color: var(--color-white);
  margin-top: 5rem;
  font-size: 2rem;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow:inset  1px 2px 2px rgba(16, 24, 40, 0.1)
}

.button:hover {
  background: transparent;
  box-shadow: none;
  
  color: #f75c14;
 
}

::placeholder {
  color: transparent;
}



@media only screen and (max-width: 1140px) {
  .headingContainer {
    width: 33.7rem;
    line-height: 120%;
  }

  .frameParent {
    gap: 14rem;
  }

  .inputText {
    font-size: 1.2rem;
  }

  .textarea {
    height: 12rem;
    width: 40rem;
  }

  .button {
    margin-top: 10rem;
    left: 30rem;
  }

  .formRow {
    width: 40rem;
  }

}


@media only screen and (max-width: 980px) {
  .frameParent {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 15rem;
    width: 100%;
    gap: 6rem;
  }

  .orangeBox {
    width: 23.9rem;
    height: 4rem;
    top: 3rem;
    left: 9rem;
  }

  .inputText {
    font-size: 1rem;
    height: 70%;
    border: 1px solid rgba(0, 0, 0, 0.6);
    padding-left: 1rem;
  }

  ::placeholder {
    color: lightgray;
    font-size: 1.4rem;
  }

  .label {
    display: none;
  }

  .formRow {
    width: 100%;
    gap: 6rem;
    margin-bottom: 3.2rem;
  }

  .nameParent {
    width: 70%;
  }

  .textarea {
    width: 100%;
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.6);
    margin-top: 0;
    padding: 1rem 0 0 1rem;
  }

  .button {
    left: 0;
    margin-top: 10rem;
    font-size: 1.4rem;
  }

  .headingParent {
    font-size: 4.8rem;
  }
}


@media only screen and (max-width: 630px) {
  

  .orangeBox {
    top: 1.5rem;
    left: 5rem;
    width: 10.9rem;
    height: 2.5rem;
  }

  .formSectionContainer {
    gap: 4rem;
  }

  .nameParent {
    font-size: 1rem;
  }

  .frameParent {
    margin: 0;
  }

  .headingParent {
    font-size: 2.4rem;
    height: auto;
    width: 25rem;
  }

  .nameParent {
    width: 90%;
  }

  .formRow {
    gap: 4rem;
    margin-bottom: 1.2rem;
  }

  .inputText {
    height: 50%;
    font-size: 1rem;
  }

  ::placeholder {
    font-size: 1rem;
    color: rgb(217, 215, 215);
  }

  .button {
    font-size: 1rem;
    padding: 1rem 1.4rem;
  }
}

