.heading {
  letter-spacing: 0.04em;
  display: inline-block;
  width: max-content;
}

@media only screen and (max-width: 1180px) {
  .heading {
    font-size: var(--font-size-5xl);
  }
}

@media only screen and (max-width: 840px) {
  .heading {
    font-size: var(--font-size-xl);
  }
}

@media only screen and (max-width: 680px) {
  .heading {
    font-size: var(--text-md-medium-size);
  }
}

@media only screen and (max-width: 340px) {
  .heading {
    font-size: var(--font-size-smi);
  }
}
