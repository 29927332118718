@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");
body {
  width: 100%;
  margin: 0;
  line-height: normal;
  box-sizing: border-box;
  min-height: 100vh;
  height: 100%;
}

html {
  font-size: 62.5%;
  height: 100%;
}
:root {
  /* fonts */
    
  --font-open-sans: "Open Sans";
  --font-poppins: Poppins;
  --subhead-2: Anton;
  --text-md-medium: Inter;

  /* font sizes */
  --text-md-medium-size: 16px;
  --font-size-sm: 14px;
  --font-size-xs: 12px;
  --font-size-5xl: 24px;
  --font-size-13xl: 32px;
  --font-size-44xl: 63px;
  --font-size-xl: 20px;
  --font-size-51xl: 70px;
  --subhead-2-size: 48px;
  --font-size-smi: 13px;
  --font-size-39xl: 58px;
  --font-size-4xs: 9px;
  --font-size-2xs: 11px;
  --font-size-base: 1.6rem;
  --font-size-sm: 1.4rem;
  --font-size-xs: 1.2rem;
  --font-size-5xl: 2.4rem;
  --font-size-44xl: 6.3rem;
  --font-size-13xl: 3.2rem;
  --font-size-45xl: 6.4rem;
  --font-size-xl: 2rem;
  --font-size-39xl: 5.8rem;
  --font-size-51xl: 7rem;
  --subhead-2-size: 4.8rem;
  --font-size-77xl: 9.6rem;

  /* Colors */
  --color-whitesmoke: #f0f0f0;
  --main: #fff;
  --color-silver-100: #bbb;
  --color-darkslategray: #373631;
  --color-darkslategray-100: #333;
  --color-black: #000;
  --color-tomato-100: #f26422;
  --color-tomato-200: rgba(242, 100, 34, 0.38);
  --color-tomato-300: rgba(242, 100, 34, 1);
  --color-gray-100: #fbfbfb;
  --color-gray-200: #807f7f;
  --color-gray-400: #222;
  --color-gray-300: #010101;
  --color-gainsboro-200: #e4e4e4;
  --color-gainsboro-100: #d9d9d9;
  --color-darkgray: #afafaf;
  --secondary-color: #606060;
  --color-blueviolet: #9747ff;
  --color-red: #ff0000;
  --tint-and-hover: #e7520d;
  --color-whitesmoke: #f0f0f0;
  --color-white: #fff;
  --color-silver: #bbb;
  --color-darkslategray-100: #373631;
  --color-darkslategray-200: #333;
  --color-black: #000;
  --color-tomato-100: #f26422;
  --color-tomato-200: rgba(242, 100, 34, 0.38);
  --color-lightgray-100: #ccc;
  --color-gray-100: #fbfbfb;
  --color-gray-200: #807f7f;
  --color-gray-300: #222;
  --tint-and-hover: #e7520d;
  --color-dimgray-100: #666;
  --color-gainsboro-100: #e4e4e4;
  --color-gainsboro-200: #dfdfdf;
  --color-gainsboro-300: #d9d9d9;
  --color-darkgray-100: #afafaf;

  /* Gaps */
  --gap-621xl: 640px;
  --gap-9xs: 4px;
  --gap-5xl: 24px;
  --gap-141xl: 160px;
  --gap-base: 16px;
  --gap-6xs: 7px;
  --gap-xs: 12px;
  --gap-5xs: 8px;
  --gap-29xl: 48px;
  --gap-13xl: 32px;

  --gap-621xl: 64rem;
  --gap-9xs: 0.4rem;
  --gap-5xl: 2.4rem;
  --gap-141xl: 16rem;
  --gap-base: 1.6rem;
  --gap-6xs: 0.7rem;
  --gap-xs: 1.2rem;
  --gap-5xs: 0.8rem;
  --gap-189xl: 20.8rem;
  --gap-25xl: 4.4rem;
  --gap-24xl: 4.3rem;
  --gap-13xl: 3.2rem;
  --gap-301xl: 32rem;
  --gap-29xl: 4.8rem;
  --gap-21xl: 4rem;
  --gap-61xl: 8rem;
  --gap-74xl: 9.3rem;
  --gap-75xl: 9.4rem;
  --gap-76xl: 9.5rem;
  --gap-273xl: 29.2rem;
  --gap-18xl: 3.7rem;

  /* Paddings */
  --padding-base: 16px;
  --padding-269xl: 288px;
  --padding-xs: 12px;
  --padding-xl: 20px;

  --padding-base: 1.6rem;
  --padding-269xl: 28.8rem;
  --padding-61xl: 8rem;
  --padding-xs: 1.2rem;
  --padding-xl: 2rem;
  --padding-24xl: 4.3rem;
  --padding-5xs: 0.8rem;
  --padding-27xl: 4.6rem;
  --padding-25xl: 4.4rem;
  --padding-22xl: 4.1rem;
  --padding-20xl: 3.9rem;
  --padding-37xl: 5.6rem;
  --padding-31xl: 5rem;
  --padding-11xl: 3rem;
  --padding-5xl: 2.4rem;
  --padding-21xl: 4rem;
  --padding-221xl: 24rem;
  --padding-9xl: 2.8rem;
  --padding-2xs: 1.1rem;
  --padding-3xs: 1rem;
  --padding-8xl: 2.7rem;
  --padding-4xl: 2.3rem;
  --padding-541xl: 56rem;
  --padding-11xs: 0.2rem;
  --padding-45xl: 6.4rem;
  --padding-130xl: 14.9rem;
  --padding-250xl: 26.9rem;
  --padding-13xl: 3.2rem;
  --padding-32xl-3: 5.13rem;
  --padding-70xl: 8.9rem;
  --padding-38xl: 5.7rem;

  /* border radiuses */
  --br-8xs: 5px;

  /* Effects */
  --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
