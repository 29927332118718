.logo {
  width: 11.2rem;
  height: 11.2rem;
  object-fit: cover;
}
.heading {
  letter-spacing: 0.04em;
  font-weight: 300;
}

.home1 {
  letter-spacing: 0.04em;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  width: max-content;
}
.home1:hover {
  color: var(--color-tomato-100);
}

.component{
  position: relative;
  width: max-content;
}

.links {
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  gap: var(--gap-5xs);
  font-size: var(--font-size-sm);
  box-sizing: border-box;
}
.quickLinks {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  gap: var(--gap-base);
}


.quickLinksParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 15%;
  width: 40%;
}

.copyright,
.privacyPolicy {
  position: relative;
  letter-spacing: 0.04em;
  width: max-content;
}

.footerIgnore {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: inherit;
  border-top: 1px solid var(--color-silver-100);
  height: 5.2rem;
  font-size: var(--font-size-sm);
  color: var(--color-darkslategray-100);
}

.vectorIcon {
  height: 13.5px;
  cursor: pointer;
}
.iconParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}

.contactContent {
  letter-spacing: 0.04em;
  display: inline-block;
  width: max-content;
  flex-shrink: 0;
  cursor: default;
}

.parent {
  flex-direction: column;
  gap: var(--gap-xs);
}

.frameParent,
.parent {
  display: flex;
  align-items: flex-start;
}
.frameParent {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-6xs);
  font-size: var(--font-size-xs);
}

.socialIcons {
  position: relative;
  width:20px;
  height: 20px;
  cursor: pointer;
}

.socials {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}

.footerMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  height: 240px;
  overflow: hidden;
  margin: 0 auto;
  z-index: 1000;
}

.footerContainer {
  background-color: var(--main);
  width: inherit;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  font-family: var(--font-open-sans);
  overflow: hidden;
  margin-top: 25rem;
}

@media only screen and (max-width: 1130px) {
  .footerMenu {
    font-size: var(--font-size-xl);
    width: 90%;
  }

}

@media only screen and (max-width: 875px) {
  .footerMenu {
    font-size: var(--text-md-medium-size);
  }

  .logo {
    width: 8rem;
    height: 8rem;
  }

  .quickLinksParent {
    width: 50%;
  }

  .socials {
    gap: 16px;
  }

  .socialIcons {
    width: 2rem;
    height: 2rem;
  }

  .links {
    font-size: var(--font-size-2xs);
  }
  
  .parent {
    font-size: var(--font-size-2xs);
  }

  .vectorIcon {
    height: 12px;
  }

  .footerIgnore {
    font-size: var(--font-size-xs);
  }
}

@media only screen and (max-width: 650px) {
  .logoContainer {
    display: none;
  }
  .quickLinksParent {
    flex-direction: column;
    align-items: flex-start;
    gap: 4rem;
  }

  .footerMenu {
    align-items: flex-start;
    justify-content: space-between;
    height: 300px;
    margin:  0 auto;
    padding-top: 50px;
  }

  .socials {
    flex-direction: column;
  }

  .heading {
    font-weight: 600;
  }

  .footer {
    font-size: var(--font-size-5xl)
  }

  .footerIgnore {
    justify-content: space-around;
  }

  .links {
    font-size: var(--font-size-xs);
  }

  .frameParent {
    color: var(--color-gray-200);
    gap: var(--gap-base)
  }

  .socialIcons {
    width: 17px;
    height: 17px;
  }

  .vectorIcon {
    fill: var(--color-gray-200);
  }

  .footer {
    margin-top: 15rem;
    border-top: .5px black solid;
  }
  
}


