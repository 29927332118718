/* ------------------------------------------------------------ */
.content {
  display: flex;
  align-items: center;
  margin-top: 12rem;
  flex-direction: column;
  justify-content: center;
  gap: var(--gap-141xl);
}

/* --------------------Our Story----------------------------- */

.ImgParentMobile {
  display: none;
}

.ourStory {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-141xl);
}

.headingParent {
  position: relative;
}

.ourStoryHeading {
  position: relative;
  letter-spacing: 0.06em;
  line-height: 112.54%;
  z-index: 100;
}

.text{
  color: var(--color-tomato-100);
}

.ourStoryContainer {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: 0.06em;
  line-height: 138.04%;
  font-family: var(--font-open-sans);
  display: inline-block;
  width: 72rem;
  flex-shrink: 0;
  text-align: justify;
}


/* ----------------------------------------------- */
.ImgParent {
  position: relative;
}



.Img {
  position: relative;
  width: 97.9rem;
  height: 39.5rem;
  object-fit: cover;
  margin-left: 3rem;
  z-index: 100;
}


/* ----------------Director Section------------------------------- */

.directorSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 13rem;
  font-size: var(--font-size-base);
  font-family: var(--font-open-sans);
}

.paragraph {
  position: relative;
  letter-spacing: 0.05em;
  display: inline-block;
  width: 64rem;
  font-weight: 500;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-5xl);
}

.gridContainer {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 1.5rem;
  font-size: var(--subhead-2-size);
  color: var(--color-darkgray-100);
  font-family: var(--subhead-2);
}

.gridItem1 {
  background-color: var(--main);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-25xl) var(--padding-xl)
    var(--padding-5xl);
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-21xl);
  font-size: var(--font-size-xl);
  color: var(--color-tomato-100);
  font-family: var(--font-open-sans);
}

.Establishment {
  position: relative;
  letter-spacing: 0.06em;
  line-height: 138.04%;
  font-weight: 600;
  display: inline-block;
  width: 17.2rem;
}

.textStyle {
  margin: 0;
}

.year {
  position: relative;
  font-size: var(--font-size-51xl);
  letter-spacing: 0.05em;
  font-family: var(--subhead-2);
  display: inline-block;
  flex-shrink: 0;
  color: #aaa;
}

.span {
  color: #888;
}


.orangeBox3 {
  border: 0;
  background-color: var(--color-tomato-100);
  width: 24rem;
  height: 24rem;
}

.gridItem4 {
  background-color: var(--main);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xl) 7rem var(--padding-5xl) var(--padding-5xl);
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-21xl);
}

.directorName {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 130.04%;
  color: #a6a6a6;
}
.director {
  position: relative;
  font-size: var(--font-size-xl);
  letter-spacing: 0.06em;
  line-height: 138.04%;
  font-weight: 600;
  font-family: var(--font-open-sans);
  color: var(--color-tomato-100);
}

/* ----------------------------------------------- */


.orangeBox {
  position: relative;
  top: 8.6rem;
  left: 1.6rem;
  background-color: var(--color-tomato-100);
  width: 14.7rem;
  height: 4.4rem;
}

a {
  text-decoration: none;
}

.orangeBox2 {
  background-color: var(--color-tomato-100);
  position: absolute;
  top: 17.7rem;
  left: 0;
  width: 70.6rem;
  height: 24.8rem;
}

.orangeBox4 {
  position: relative;
  top: 9rem;
  left: 3.9rem;
  background-color: var(--color-tomato-100);
  width: 22rem;
  height: 4rem;
}

/* -------------------------------------------- */
.pageHeading {
  position: relative;
  letter-spacing: 0.06em;
  line-height: 112.54%;
}

.heroSection {
  width: 100%;
  height: 53.1rem;
  display: flex;
  flex-direction: column;
  padding: 5.8rem 9.6rem;
  box-sizing: border-box;
  justify-content: flex-end;
  background-image: url(/public/hero-section1@3x.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  font-size: var(--font-size-44xl);
  color: var(--main);
}
/* -------------------------------------------- */

.aboutUs {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-39xl);
  color: var(--color-black);
  font-family: var(--subhead-2);
}

/* --------------------------------------------- */

.companyDetailsMobile {
  display: none;
}
.companyDetailsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-301xl);
  font-size: var(--font-size-sm);
  color: var(--color-gray-300);
  font-family: var(--font-open-sans);
}

.short {
  display: none;
}

.companyDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-base);
}

.iconGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
}

.address {
  position: relative;
  letter-spacing: 0.04em;
}

.locationIcon {
  position: relative;
  width: 1.4rem;
  height: 1.7rem;
}

.callIcon,
.phoneIcon
{
  position: relative;
  width: 1.6rem;
  height: 1.6rem;
}
.phoneIcon
{
  width: 1.7rem;
}
.mailIcon {
  position: relative;
  width: 1.9rem;
  height: 1.3rem;
}

.frameParent1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 3.9rem;
  font-size: 3.6rem;
  color: #606060;
  font-family: var(--subhead-2);
}

.gstNumberParent {
  background-color: var(--color-darkgray-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs) var(--padding-5xl);
  align-items: flex-end;
  justify-content: center;
  gap: var(--gap-5xl);
}

.Title {
  position: relative;
  letter-spacing: 0.05em;
}
.gstNumber {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: 0.04em;
  font-family: var(--font-open-sans);
  color: var(--main);

}

.business {
  position: relative;
  letter-spacing: 0.05em;
  display: inline-block;
  width: 20rem;
  flex-shrink: 0;
}
.businessContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--color-darkgray-100);
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-11xl) var(--padding-5xl);
  justify-content: space-between;
  font-size: var(--font-size-xl);
  color: var(--color-white);
}

/* ----------------------------------------------- */

.frameParent2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 36rem;
}

.rectangleContainer {
  position: relative;
}

.targetSectors {
  position: relative;
  z-index: 100;
  letter-spacing: 0.04em;
}

/* ---------------------------------------------------- */

@media only screen and (max-width: 1180px) {
  .ourStory {
    gap: 12rem;
  }

  .ourStoryContainer {
    width: 62rem;
  }

  .directorSection {
    gap: 8rem;
  }

  .paragraph {
    width: 50rem;
  }

  .year {
    font-size: 5.6rem;
  }

  .orangeBox3 {
    width: 21rem;
    height: 21rem;
  }

  .gridItem1 {
    padding: 2rem 0.2rem 1rem 2.2rem;
    gap: 3rem;
    justify-content: space-between;
  }
  
  .gridContainer {
    gap: 1rem;
  }
  
  .gridItem4 {
    padding: 2rem 2.2rem 1rem 2.2rem;
    gap: 3rem;
    justify-content: space-between;
  }

  .directorName {
    line-height: 120%;
  }

  .companyDetailsContainer {
    gap: 20rem;
  }

  .frameParent2 {
    gap: 18rem;
  }
}

@media only screen and (max-width: 1000px) {
  .ourStory {
    gap: 10rem;
  }

  .ourStoryHeading {
    width: 24rem;
    letter-spacing: 0.04em;
  }

  .orangeBox {
    top: 14rem;
    left: 3.6rem;
  }

  .ourStoryContainer {
    width: 50rem;
    font-weight: 600;
  }

  .Img {
    width: 81.9rem;
  }

  .directorSection {
    align-items: flex-start;
    gap: 7rem;
  }

  .paragraph {
    width: 43rem;
    font-size: 1.4rem;
  }

  .orangeBox3 {
    width: 16rem;
    height: 16rem;
  }

  .gridItem1 {
    gap: 2rem;
    padding: 2rem 1.2rem 1rem 1.2rem;
  }

  .gridItem4 {
    padding: 1rem 1.2rem 1rem 1.2rem;
  }

  .directorName {
    font-size: 3.6rem;
  }

  .year {
    font-size: 3.6rem;
  }

  .Establishment {
    font-size: 1.4rem;
    width: 13.2rem;
  }

  .director {
    font-size: 1.4rem;
  }

  .frameParent1 {
    gap: 2.9rem;
    font-size: 3rem;
  }

  .gstNumber {
    font-size: 1.4rem;
  }

  .businessContainer {
    font-size: 1.6rem;
    padding: 2rem 1.8rem 2.5rem 1.8rem;
  }

  .gstNumberParent {
    gap: 2rem;
  }

  .companyDetailsContainer {
    gap: 12rem;
    font-size: var(--font-size-xs);
  }

  .companyDetails {
    gap: 1.4rem;
  }

  .iconGroup {
    gap: 1.2rem;
  }

  .locationIcon {
    width: 1.2rem;
    height: 1.5rem;
  }

  .callIcon {
    width: 1.4rem;
    height: 1.4rem;
  }

  .phoneIcon {
    width: 1.4rem;
    height: 1.4rem;
  }

  .mailIcon {
    width: 1.7rem;
    height: 1.1rem;
  }

  .frameParent2 {
    gap: 7rem;
  }

  .targetSectors {
    width: 32rem;
    line-height: 110%;
  }

  .orangeBox4 {
    top: 13rem;
    left: 4.9rem;
    width: 18rem;
  }

  .rectangleContainer {
    font-size: 5.2rem;
  }
}

@media only screen and (max-width: 840px) {

  .content{
    margin-top: 8rem;
  }
  .ourStory {
    gap: 8rem;
  }

  .ourStoryHeading {
    width: 20rem;
    letter-spacing: 0.04em;
    font-size: 4.8rem;
  }

  .orangeBox {
    top: 12.7rem;
    left: 2.6rem;
  }

  .ourStoryContainer {
    width: 38rem;
    font-weight: 600;
    font-size: 1.2rem;
  }

  .Img {
    width: 64.9rem;
    height: 32.5rem;
  }

  .orangeBox2 {
    height: 18.8rem;
    width: 53.5rem;
    top: 16.7rem
  }

  .directorSection {
    align-items: flex-start;
    gap: 5rem;
  }

  .paragraph {
    width: 35rem;
    font-size: 1.1rem;
    font-weight: 600;
  }

  .orangeBox3 {
    width: 14rem;
    height: 14rem;
  }

  .gridItem1 {
    gap: 1rem;
    padding: 1rem 1.2rem 1rem 1.2rem;
  }

  .gridItem4 {
    padding: 1rem 1.2rem 1rem 1.2rem;
    gap: 1rem;
  }

  .directorName {
    font-size: 3.2rem;
  }

  .textContainer {
    gap: 2rem;
  }

  .year {
    font-size: 3.6rem;
  }

  .Establishment {
    font-size: 1.4rem;
    width: 11rem;
  }

  .director {
    font-size: 1.4rem;
  }

  .frameParent1 {
    gap: 1.9rem;
    font-size: 2rem;
  }

  .full {
    display: none;
  }

  .short {
    display: inline-block;
  }
  .gstNumber {
    font-size: 1.4rem;
  }

  .businessContainer {
    font-size: 1.6rem;
    padding: 2rem 0.8rem 1.5rem 1.8rem;
  }

  .business {
    width: 15rem;
  }

  .gstNumberParent {
    gap: 1.5rem;
    align-items: flex-start;
  }

  .companyDetailsContainer {
    gap: 8rem;
    font-size: var(--font-size-xs);
  }

  .companyDetails {
    gap: 1rem;
  }

  .iconGroup {
    gap: 1.2rem;
  }

  .locationIcon {
    width: 1.2rem;
    height: 1.5rem;
  }

  .callIcon {
    width: 1.4rem;
    height: 1.4rem;
  }

  .phoneIcon {
    width: 1.4rem;
    height: 1.4rem;
  }

  .mailIcon {
    width: 1.7rem;
    height: 1.1rem;
  }

  .frameParent2 {
    gap: 3rem;
  }

  .targetSectors {
    width: 25rem;
    line-height: 110%;
  }

  .orangeBox4 {
    top: 11rem;
    left: 3.9rem;
    width: 15rem;
  }

  .rectangleContainer {
    font-size: 4.2rem;
  }


}

@media only screen and (max-width: 680px) {
  .heroSection {
    display: none;
  }

  .ImgParent {
    display: none;
  }

  .ourStory {
    flex-direction: column;
  }

  .ImgParentMobile {
    display: block;
    position: relative;
  }

  .Img {
    width: 40rem;
    height: 26.5rem;
  }

  .orangeBox2 {
    background-color: var(--color-tomato-100);
    width: 38rem;
    height: 25rem;
    top: 4rem
  }

  .ourStoryHeading {
    line-height: 120%;
  }

  .orangeBox {
    width: 11.7rem;
  }

  .ourStoryContainer {
    font-weight: 700;
    width: 35rem;
  }

  .directorSection {
    flex-direction: column-reverse;
  }

  .gridContainer {
    grid-template-columns: 1fr;
  }

  .orangeBox3 {
    height: 4rem;
    width: 24.5rem;
  }

  .gridItem1, .gridItem4 {
    background-color: var(--color-whitesmoke);
    gap: 6rem;
    padding: 2rem 5rem 0rem 2rem;
    box-shadow: none;
  }

  .gridItem4 {
    gap: 6rem;
    padding: 2rem 1rem 2rem 1rem;
  }

  .year {
    margin: 0;
    font-size: 8rem;
  }

  .span {
    color: #aaa;
  }

  .span1 {
    color: #888;
  }

  .aboutUs {
    background-color: var(--color-white);
  }

  .directorName {
    font-size: 5.2rem;
  }

  .content {
    align-items: flex-start;
    margin-left: 2.5rem;
    gap: 8rem;
  }

  .textContainer {
    gap: 4rem;
  }

  .paragraph {
    font-size: 1.2rem;
    font-weight: 600;
    width: 31rem;
  }

  .companyDetails {
    display: none;
  }

  .companyDetailsMobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-base);
    font-size: 1rem;
    font-family: var(--font-open-sans);
    color: var(--color-gray-300);
  }

  .gstNumberParent {
    width: 17rem;
    padding: .5rem 0rem .5rem 1rem;
    gap: 1rem;
  }

  .Title {
    font-size: 1.4rem;
  }

  .gstNumber {
    font-size: 1rem;
    margin-left: 7rem;
  }

  .businessContainer {
    font-size: 1.2rem;
    padding: 1rem .8rem 1rem 1rem;
    width: 16.2rem;
  }

  .frameParent2 {
    flex-direction: column;
    gap: 10rem;
    align-items: flex-start;
  }

  .rectangleContainer {
    font-size: 3.6rem;
  }

  .targetSectors {
    width: 20rem;
  }

  .orangeBox4 {
    left: 2.5rem;
    top: 10rem;
    width: 10rem;
  }

}

@media only screen and (max-width: 680px) {
  .Img {
    width: 28rem;
    height: 17.2rem;
  }

  .orangeBox2 {
    width: 28rem;
    height: 16rem;
  }

  .ourStoryContainer {
    width: 30rem;
    font-size: 1rem;
  }

  .ourStoryHeading {
    font-size: 4rem;
    width: 16rem;
  }

  .orangeBox {
    top: 10.5rem;
    left: 2rem;
    height: 3.4rem;
    width: 9.7rem;
  }

  .paragraph {
    font-size: 1rem;
    width: 26rem;
    text-align: justify;
  }

  .frameParent1 {
    font-size: 1.4rem;
  }

  .gstNumberParent {
    width: 14rem;
    padding-left: 0.5rem;
  }

  .gstNumber {
    margin-left: 5rem;
    font-size: 0.8rem;
  }

  .Title {
    font-size: 1.2rem;
  }

  .businessContainer {
    width: 14rem;
    font-size: 1rem;
    padding: 1rem 0.5rem 1rem 0.5rem;
  }
}