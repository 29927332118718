.Img1 {
  width: 40.8rem;
  height: 27.2rem;
  object-fit: cover;
}


.container1 {
  justify-content: flex-end;
  margin-right: 15rem;
}

.orangeBox1 {
  background-color: var(--color-tomato-100);
  width: 11.4rem;
  height: 27.2rem;
}

.textParent1, .textParent2, .textParent  {
  position: relative;
  left: 7.1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  cursor: pointer;
}

.Text1, .Text2, .Text {
  text-align: right;
  letter-spacing: 0.06em;
  line-height: 6.4rem;
  display: inline-block;
  width: 40rem;
}
.Arrow1 {
  width: 34.1rem;
  height: 2.22rem;
}

/* --------------------------------------------------- */


.Img2 {
  width: 30.4rem;
  height: 40.6rem;
  object-fit: cover;
}

.orangeBox2 {
  background-color: var(--color-tomato-100);
  width: 10.7rem;
  height: 40.6rem;
}

.Text2 {
  text-align: left;
  width: 35rem;
}

.Arrow2 {
  width: 36.75rem;
  height: 2.21rem;
}

.textParent2 {
  left: -8.1rem;
}

/* ----------------------------------------------------- */

.facebookIcon,
.linkedinIcon,
.twitterIcon,
.instaIcon {
  position: relative;
  width: 2.8rem;
  height: 2.8rem;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}

.linkedinIcon {
  width: 2.7rem;
}

.facebookIcon {
  width: 2.5rem;
  height: 2.6rem;
}
.twitterIcon {
  width: 2.6rem;
  height: 2.6rem;
}

.socialsContainer {
  background-color: var(--color-tomato-100);
  display: flex;
  flex-direction: column;
  padding: var(--padding-13xl) var(--padding-base);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xl);
  width: 2.5rem;
  right: 0;
  position: absolute;
  top: 35rem;
}

/* -------------------------------------------------------------- */

.imgParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.blogPost {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  overflow: hidden;
  text-align: left;
  font-size: var(--subhead-2-size);
  color: var(--color-gray-300);
  font-family: var(--subhead-2);
}


.frameParent {
  text-align: left;
  font-size: var(--subhead-2-size);
  color: var(--color-gray-300);
  font-family: var(--subhead-2);
  margin-top: 30rem;
}

.container, .container1, .container2 {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10rem 17.5rem;
}

.orangeBox {
  background-color: var(--color-tomato-100);
  width: 11.4rem;
  height: 39.1rem;
}

.Text {
  text-align: left;
}

.Arrow {
  width: 40.15rem;
  height: 2.21rem;
}

.textParent {
  left: -7.1rem;
  cursor: pointer;
}

.Img {
  width: 26.1rem;
  height: 39.1rem;
  object-fit: cover;
}


/* -------------------------------------------------------- */
.container1, .container2 {
  display: none;
}

@media only screen and (max-width: 1120px) {
  .container, .container1, .container2 {
    margin: 10rem 10rem;
  }
}

@media only screen and (max-width: 1000px) {
  .frameParent {
    margin-top: 20rem;
  }
  
  .orangeBox {
    width: 10.4rem;
    height: 30.1rem;
  }

  .Img {
    width: 22.1rem;
    height: 30.1rem;
  }

  .Img2 {
    width: 25.4rem;
    height: 30.6rem;
  }
  
  .orangeBox2 {
    width: 9.7rem;
    height: 30.6rem;
  }

  .orangeBox1 {
    width: 8.4rem;
    height: 20.2rem;
  }

  .Img1 {
    width: 35.8rem;
    height: 20.2rem;
  }

  .container, .container1, .container2 {
    margin: 7rem 7rem;
  }

  .socialsContainer {
    top: 22rem;
  }

  .Text1, .Text2, .Text {
    line-height: 4.2rem;
    font-size: 3.2rem;
    width: 32rem;
  }

  .textParent2 {
    left: -6rem;
    align-items: flex-start;
  }

  .Arrow {
    width: 29.15rem;
  }
  
  .Arrow2 {
    width: 25.75rem;
  }
  
  .Arrow1 {
    width: 26.1rem;
  }

  .textParent1 {
    left: 4.1rem;
  }

  .textParent {
    left: -6.1rem;
    align-items: flex-start;
  }

}

@media only screen and (max-width: 840px) {

  .orangeBox {
    width: 7.4rem;
    height: 23.1rem;
  }

  .Img {
    width: 18.1rem;
    height: 23.1rem;
  }

  .Img2 {
    width: 19.4rem;
    height: 27.6rem;
  }
  
  .orangeBox2 {
    width: 7.7rem;
    height: 27.6rem;
  }

  .orangeBox1 {
    width: 6.8rem;
    height: 17.2rem;
  }

  .Img1 {
    width: 28.8rem;
    height: 17.2rem;
  }

  .container, .container1, .container2 {
    margin: 8rem 6rem;
  }

  .container1 {
    margin-right: 4rem;
  }

  .socialsContainer {
    top: 22rem;
  }

  .Text1, .Text2, .Text {
    line-height: 3.2rem;
    font-size: 2.4rem;
    width: 19rem;
  }

  .textParent2 {
    left: -4rem;
  }

  .Arrow {
    width: 18.15rem;
  }
  
  .Arrow2 {
    width: 15.75rem;
  }
  
  .Arrow1 {
    width: 18.1rem;
  }

  .textParent1 {
    left: 3.1rem;
  }

  .textParent {
    left: -4.1rem;
  }

}


@media only screen and (max-width: 650px) {
  .orangeBox {
    width: 4rem;
    height: 15.1rem;
  }

  .Img {
    width: 11.1rem;
    height: 15.1rem;
  }

  .Img2 {
    width: 12rem;
    height: 17rem;
  }
  
  .orangeBox2 {
    width: 4.5rem;
    height: 17rem;
  }

  .orangeBox1 {
    width: 3.8rem;
    height: 10.2rem;
  }

  .Img1 {
    width: 14.8rem;
    height: 10.2rem;
  }

  .container, .container1, .container2 {
    margin: 5rem 4rem;
  }

  .container1 {
    margin-right: 3rem;
  }

  .Text1, .Text2, .Text {
    line-height: 1.8rem;
    font-size: 1.4rem;
    width: 11.5rem;
  }

  .textParent2 {
    left: -3rem;
    gap: 0;
  }

  .Arrow {
    width: 10.15rem;
  }
  
  .Arrow2 {
    width: 10.75rem;
  }
  
  .Arrow1 {
    width: 10.1rem;
  }

  .textParent1 {
    left: 1.9rem;
    gap:0;
  }

  .textParent {
    left: -2.1rem;
    gap:0;
  }

  .frameParent {
    margin-top: 12.5rem;
  }


.instaIcon {
  width: 2rem;
  height: 2rem;
}

.linkedinIcon {
  width: 1.9rem;
}

.facebookIcon {
  width: 1.7rem;
  height: 1.8rem;
}
.twitterIcon {
  width: 1.8rem;
  height: 1.8rem;
}

.socialsContainer {
  padding: var(--padding-xl) var(--padding-xs);
  gap: var(--gap-5xs);
  width: 1.2rem;
  right: 0;
  position: absolute;
  top: 12.5rem;
}

}