.contactUsHeading {
  letter-spacing: 0.04em;
}
.facebookIcon,
.linkedinIcon,
.twitterIcon,
.instaIcon {
  position: relative;
  width: 2.8rem;
  height: 2.8rem;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;

}

.Img {
  display: none;
}

.linkedinIcon
{
  width: 2.7rem;
}
.facebookIcon
{
  width: 2.5rem;
  height: 2.6rem;
}
.twitterIcon {
  width: 2.6rem;
  height: 2.6rem;
}
.socials {
  background-color: var(--color-tomato-100);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-13xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-13xl);
  width: fit-content;
}
.companyDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-base);
  font-size: 1.6rem;
  font-family: var(--font-open-sans);
  margin-top: 7rem;
}

.iconGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
}

.address {
  position: relative;
  letter-spacing: 0.04em;
}

.locationIcon {
  position: relative;
  width: 1.4rem;
  height: 1.7rem;
}

.callIcon,
.phoneIcon
{
  position: relative;
  width: 1.6rem;
  height: 1.6rem;
}
.phoneIcon
{
  width: 1.7rem;
}
.mailIcon {
  position: relative;
  width: 1.9rem;
  height: 1.3rem;
}
.contactUsParent {
  text-align: left;
  font-size: var(--font-size-77xl);
  color: var(--color-darkslategray-200);
  font-family: var(--subhead-2);
}

@media only screen and (max-width: 1140px) {
  .contactUsParent {
    font-size: 8.4rem;
    /* color: var(--color-tomato-100); */
  }

  .socials {
    gap: 2.8rem;
    padding: 1.2rem 2.8rem;
  }

  .companyDetails {
    font-size: 1.4rem;
    gap: 1.4rem;
    margin-top: 7rem;
  }
}

@media only screen and (max-width: 900px) {
  .contactUsParent {
    font-size: 7.2rem;
  }

  .socials {
    gap: 2rem;
    padding: 1rem 1.8rem;
  }


}

@media only screen and (max-width: 750px) {
  .Img {
    display: block;
    position: relative;
    width: 36.3rem;
    height: 40.7rem;
    object-fit: cover;
  }

  .contactUsHeading {
    margin-bottom: 4rem;
    color: var(--color-tomato-100);
  }

  .contactUsParent {
    font-size: 4rem;
  }

  .socials {
    position: absolute;
    right: 0;
    top: 27rem;
    flex-direction: column;
    gap: 1.4rem;
    padding: 1.8rem 1rem;
  }

  .companyDetails {
    margin-top: 5rem;
  }
}

@media only screen and (max-width: 540px) {
  .Img {
    width: 25.3rem;
    height: 30.7rem;
  }


  .contactUsParent {
    font-size: 3.2rem;
  }

  .socials {
    top: 22rem;
    gap: 1.2rem;
    padding: 2rem 1.2rem;
  }

  .companyDetails {
    margin-top: 5rem;
  }

  .linkedinIcon
  {
    width: 1.8rem;
    height: 1.8rem;
  }
  .facebookIcon
  {
    width: 1.8rem;
    height: 1.9rem;
  }
  .twitterIcon {
    width: 1.9rem;
    height: 1.8rem;
  }
  .instaIcon {
    width: 2rem;
    height: 2rem;
  }

  .companyDetails {
    font-size:  1.2rem;
    
  }
}


