.frameParent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: var(--gap-5xl);
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-open-sans);
}
/* -------------------------------------------------- */
.frameGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-13xl);
}

.municipal {
  position: relative;
  letter-spacing: 0.06em;
}
.municipalWrapper,
.railwaysWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  border: 1px solid var(--color-tomato-100);
  overflow: hidden;
  padding: var(--padding-5xs) var(--padding-31xl);
}

.railwaysWrapper {
  padding: var(--padding-5xs) var(--padding-37xl);
}
/* ---------------------------------------------------- */
.automotiveWrapper {
  padding: var(--padding-5xs) var(--padding-22xl);
}

.automotiveWrapper,
.waterWrapper {
  background-color: var(--color-white);
  border: 1px solid var(--color-tomato-100);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-20xl);
}

/* ===================================================== */

.constructionWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  border: 1px solid var(--color-tomato-100);
  overflow: hidden;
  padding: var(--padding-5xs) var(--padding-24xl);
}

/* ======================================================= */

.agriculuralWrapper,
.hydraulicsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  border: 1px solid var(--color-tomato-100);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-27xl);
}

.agriculuralWrapper {
  padding: var(--padding-5xs) var(--padding-25xl);
}

@media only screen and (max-width: 840px) {
  .frameParent {
    font-size: 1.6rem;
  }

  .frameGroup {
    gap: 2.6rem;
  }

  .municipalWrapper {
    padding: var(--padding-5xs) 3.95rem;
  }

  .railwaysWrapper {
    padding: var(--padding-5xs) 4.1rem;
  }

  .constructionWrapper {
    padding: var(--padding-5xs) 3.1rem;
  }

  .automotiveWrapper {
    padding: var(--padding-5xs) 2.8rem;
  }

  .waterWrapper {
    padding: var(--padding-5xs) 3.1rem;
  }

  .agriculuralWrapper {
    padding: var(--padding-5xs) 3.55rem;
  }

  .hydraulicsWrapper {
    padding: var(--padding-5xs) 3.2rem;
  }
}


@media only screen and (max-width: 680px) {
  .frameParent {
    font-size: 1.2rem;
    color: var(--color-tomato-100);
    gap: 1.2rem;
  }

  .frameGroup {
    gap: 1rem;
  }

  .municipalWrapper {
    padding: 0.4rem 2.95rem;
  }

  .railwaysWrapper {
    padding: 0.4rem 3.1rem;
  }

  .constructionWrapper {
    padding: 0.4rem 1.9rem;
  }

  .automotiveWrapper {
    padding: 0.4rem 2.2rem;
  }

  .waterWrapper {
    padding: 0.4rem 2.3rem;
  }

  .agriculuralWrapper {
    padding: 0.4rem 2.6rem;
  }

  .hydraulicsWrapper {
    padding: 0.4rem 2.5rem;
  }
}
