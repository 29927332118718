.divider {
  border-top: 1px solid var(--color-gray-200);
  box-sizing: border-box;
  width: 999px;
  height: 1px;
}

@media only screen and (max-width: 1180px) {
  .divider {
    width: 850px;
  }
}

@media only screen and (max-width: 840px) {
  .divider {
    width: 680px;
  }
}

@media only screen and (max-width: 680px) {
  .divider {
    width: 335px;
  }
}

@media only screen and (max-width: 340px) {
  .divider {
    width: 170px;
  }
}

