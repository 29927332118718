.xm1Icon {
  background-image: url("/public/7xm-1@2x.jpg");
  position: relative;
  width: inherit;
  height: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: flex-end;
}
.productsHeading {
  margin: 70px;
  font-size: inherit;
  letter-spacing: 0.1em;
  font-weight: 400;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.heroSection
{
  width: 100%;
  height: 600px;
}
/* -------------------------------------------------- */
.pageMarginIcon {
  position: absolute;
  top: 82rem;
  left: 230px;
  width: 9.68px;
  height: 814rem;
}

/* ---------------------------------------------------- */

.productsSect {
  margin-top: 12rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 240px;
  font-size: var(--font-size-13xl);
  color: var(--color-tomato-100);
  font-family: var(--font-open-sans);
}

.ironManholeCover, 
.ironGrating,
.decorativeGrating,
.agriculturalGrating,
.MeterBoxes,
.ductileIronCasting,
.Counterweights,
.other
 {
  position: relative;
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  margin-left: 200px;
}

.frame {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 4rem;
  column-gap: 4rem;
  margin-left: 100px;
}


.SectionHeading {
  margin-left: 100px;
}

.frameContainer {
  gap: var(--gap-29xl);
}

.products {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  overflow: hidden;
  text-align: left;
  font-size: 6.4rem;
  color: var(--main);
  font-family: var(--font-poppins);
}

@media only screen and (max-width: 1330px) {
  .ironManholeCover, 
  .ironGrating,
  .decorativeGrating,
  .agriculturalGrating,
  .ductileIronCasting,
  .MeterBoxes,
  .Counterweights,
  .other {
    margin-left: 80px;
  }

  .pageMarginIcon {
    left: 110px;
  }
}

@media only screen and (max-width: 1220px) {
  .ironManholeCover, 
  .ironGrating,
  .decorativeGrating,
  .agriculturalGrating,
  .ductileIronCasting,
  .MeterBoxes,
  .Counterweights,
  .other {
    margin-left: 20px;
  }

  .pageMarginIcon {
    left: 50px;
  }
}

@media only screen and (max-width: 1180px) {
  .products1 {
    margin: 40px;
  }

  .productsSect {
    gap: 150px;
  }

  .pageMarginIcon {
    height: 703rem;
  }

}

@media only screen and (max-width: 1000px) {
  .pageMarginIcon {
    display: none;
  }
  
  .ironManholeCover, 
  .ironGrating,
  .decorativeGrating,
  .agriculturalGrating,
  .ductileIronCasting,
  .MeterBoxes,
  .Counterweights,
  .other {
    margin-left: 0;
    margin: auto;
  }

  .frame {
    margin-left: 0;
  }

  .SectionHeading {
    margin-left: 0;
  }

  .productsSect {
    align-items: center;
  }

}

@media only screen and (max-width: 840px) {
  
  .productsHeading {
    font-size: 45px;
  }

  .productsSect {
    gap: 100px;
  }

}

@media only screen and (max-width: 680px) {
  .productsHeading {
    font-size: 4rem;
  }

  .frame {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .productsSect {
    margin-top: 1.5rem;
    gap: 50px;
  }

  .ironManholeCover, 
  .ironGrating,
  .decorativeGrating,
  .agriculturalGrating,
  .ductileIronCasting,
  .MeterBoxes,
  .Counterweights,
  .other {
    gap: 1.5rem;
  }

}

@media only screen and (max-width: 560px) {
  .heroSection {
    height: 200px;
  }

  .productsHeading {
    margin: 20px;
    margin-left: 12px;
    margin-bottom: 32px;
    font-size: 2rem;
    font-weight: 300;
  }

}

@media only screen and (max-width: 340px) {
  .frame {
    grid-template-columns: 1fr;
  }

}