.contactUs {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
}

.contactUsPage {
  display: flex;
  flex-direction: column;
  margin-top: 10rem;
  align-items: center;
  justify-content: center;
  gap: var(--gap-141xl);
}

.contactUsSection {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-301xl);
}

.Img {
  position: relative;
  width: 36.3rem;
  height: 40.7rem;
  object-fit: cover;
}


@media only screen and (max-width: 1140px) {
  .contactUsSection {
    gap: 12rem;
  }

  .contactUsPage {
    gap: 24rem;
  }
}

@media only screen and (max-width: 900px) {
  .contactUsSection {
    gap: 8rem;
  }

  .Img {
    width: 33rem;
    height: 37rem;
  }
}

@media only screen and (max-width: 750px) {
  .Img {
    display: none;
  }

  .contactUs {
    background-color: var(--color-white);
  }

  .contactUsPage {
    margin-top: 8rem;
    gap: 14rem;
  }

}


@media only screen and (max-width: 530px) {
  .contactUsPage {
    margin-top: 6rem;
    gap: 11rem;
    align-items: flex-start;
    margin-left: 10rem;
  }
}
@media only screen and (max-width: 450px) {
  .contactUsPage {
    margin-left: 6rem;
  }
}
@media only screen and (max-width: 380px) {
  .contactUsPage {
    margin-left: 4rem;
  }
}


